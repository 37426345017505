"use client";

import { usePathname } from "next/navigation";

import BasicPage from "@/components/BasicPage";
import { H3 } from "@/components/Headings";
import Message from "@/components/Message";
import { ButtonLink } from "@/components/forms/Button";

export default function NotFound() {
  const pathname = usePathname();
  return (
    <BasicPage>
      <H3 className="mb-4 text-center">Error: Not Found</H3>
      <Message error>
        The page <span className="font-bold">{pathname}</span> can&apos;t be
        found.
      </Message>

      <ButtonLink href="/courses">Go to Available Courses</ButtonLink>
    </BasicPage>
  );
}
